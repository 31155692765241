export const parseToVND = (price: number | undefined) => {

    if (!price) {
        return 0
    }
    return `${price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`
}
export function getTimeFromUnixTimestamp(unixTime: number): string {
  
    const currentTimestamp = Math.floor(Date.now() / 1000); // Current UNIX timestamp in seconds
    const diffInSeconds = currentTimestamp - unixTime;
  
    if (diffInSeconds < 60 * 60) {
      const diffInMinutes = Math.floor(diffInSeconds / 60);
      return `${diffInMinutes} phút trước`;
    } else if (diffInSeconds < 24 * 60 * 60) {
      const diffInHours = Math.floor(diffInSeconds / (60 * 60));
      return `${diffInHours} giờ trước`;
    } else {
      const diffInDays = Math.floor(diffInSeconds / (24 * 60 * 60));
      return `${diffInDays} ngày trước`;
    }
  }

  export const setLocalStorageItem = async (key: string, value: string): Promise<void> => {
    return new Promise<void>((resolve, reject) => {
      try {
        localStorage.setItem(key, value);
        resolve();
      } catch (error) {
        reject(error);
      }
    });
  };

  export const getLocalStorageItem = (key: string): Promise<string | null> => {
    return new Promise<string | null>((resolve, reject) => {
      try {
        const item = localStorage.getItem(key);
        resolve(item);
      } catch (error) {
        reject(error);
      }
    });
  };

  export function unixToDateString(unixTime: number): string {
    const date = new Date(unixTime * 1000);
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();
    const hours = date.getHours();
    const minutes = date.getMinutes();

    return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')} - ${day.toString().padStart(2, '0')}/${month.toString().padStart(2, '0')}/${year}`;
  }
  