import { Button, Card, Form, Input, InputNumber, Modal, Spin, message } from 'antd';
import React, { useEffect, useState } from 'react';
import { reqCalculatePrice, reqGetPromotion, reqLicensePack, reqVerifyPurchase } from '../../services';
import { CalculatePrice, LicensePack, PromotionValid } from '../../constant/types';
import './index.css';
import OTPInput from 'react-otp-input';
import { parseToVND } from '../../helper';
import { useDispatch, useSelector } from 'react-redux';
import { MANAGE_COMMON } from '../../redux/actions/actionTypes';
import { AppState } from '../../redux/reducers';
import { useHistory } from 'react-router-dom';

interface KeyProps {
    id: string;
    name: string;
    unit_price: number;
    amount?: number;

}
const RegisterKey: React.FC = () => {
    const [listLicensePack, setListLicensePack] = useState<KeyProps[]>();
    const [keyPrice, setKeyPrice] = useState<CalculatePrice>();
    const history = useHistory();
    const fetchListLicensePack = async () => {
        const data: LicensePack[] = await reqLicensePack();
        const dataPromotion: PromotionValid[] = await reqGetPromotion();
        if (data && dataPromotion) {
            const updatedData = data.map((item) => {
                const matchingPromotion = dataPromotion.find(
                    (promotion) => promotion.license_pack_id === item.id
                );
                if (matchingPromotion) {
                    return { ...item, amount: matchingPromotion.amount };
                }
                return item;
            });
            form.setFieldsValue({ items: data });
            setListLicensePack(updatedData);
        }

    };
    useEffect(() => {
        fetchListLicensePack();
    }, []);
    const [form] = Form.useForm();
    const PriceForm = Form.useWatch('items', { form, preserve: true });
    const dispatch = useDispatch();
    const { purchaseId, loading } = useSelector((app: AppState) => app.common);    
    useEffect(() => {
        onGetPriceInvoice();
    }, [PriceForm]);

    const onGetPriceInvoice = async () => {
        const data = form.getFieldValue('items');
        if (data) {
            const licensePackList = data.map((item: any) => {
                if (item.quantity) {
                    return {
                        license_pack_id: item.id,
                        quantity: item.quantity,
                    };
                }
                return null; // or handle the case when quantity is not present
            }).filter((item: any) => item !== null);
            if (licensePackList.length === 0) {
                setKeyPrice({
                    original_price: 0,
                    total: 0,
                    final: 0,
                    reduce_percent: 0
                })
                return
            }
            const finalData = { purchase_detail: licensePackList }
            const dataF = await reqCalculatePrice(finalData)
            setKeyPrice(dataF)
        }
    }

    const onPurchaseRequest = async (data: any) => {        
        const licensePackList = data.items.map((item: any) => {
            if (item.quantity) {
                return {
                    license_pack_id: item.id,
                    quantity: item.quantity,
                };
            }
            return null; // or handle the case when quantity is not present
        }).filter((item: any) => item !== null);
        if (licensePackList.length === 0) {
            message.error('Vui lòng chọn số lượng ít nhất 1 gói Key')
            return
        }
        if (licensePackList && Array.isArray(licensePackList) && licensePackList.length > 0) {
            const finalData = { purchase_detail: licensePackList }
            dispatch(MANAGE_COMMON.PURCHASE_REQUEST(finalData))
        }
    }
    const [otp, setOtp] = useState('')
    const onVerifyPurchase = async (pasteOtp?: string) => {
        const data = { code: pasteOtp || otp, batch_id: purchaseId }
        const result = await reqVerifyPurchase(data)
        if (result) {
            message.success('Đăng ký mua Key thành công')
            setOtp('')
            dispatch(MANAGE_COMMON.PURCHASE_REQUEST_REMOVE())
            fetchListLicensePack()
            history.push('/home');
        }
    }
    return (
        <div className='page_container max-h-screen overflow-y-auto'>
            <Modal open={purchaseId && purchaseId.length > 0}
                onCancel={() => {
                    dispatch(MANAGE_COMMON.PURCHASE_REQUEST_REMOVE())
                    setOtp('');
                }}
                title={'Nhập mã xác thực đã gửi về Email của bạn'}
                className='h-[140px] flex items-center justify-center'
                footer={
                    <Button type='primary' onClick={() => onVerifyPurchase()} disabled={otp.length < 6}>Xác nhận</Button>
                }

            >
                <OTPInput
                    value={otp}
                    onPaste={(e) => {
                        const text = e.clipboardData.getData('text')
                        if (text.length === 6) {
                            onVerifyPurchase(text)
                        }
                    }}
                    onChange={setOtp}
                    numInputs={6}
                    inputType='number'
                    renderSeparator={<span></span>}
                    containerStyle={{ gap: 5, }}
                    renderInput={(props) => <input {...props}
                        className='!w-[64px] !h-[64px] text-center border border-[#008B52] rounded-[12px] appearance-none m-0'
                    />}

                />
            </Modal>
            <Spin spinning={loading} fullscreen={true} />

            <Form
                form={form}
                name="dynamic_form_complex"
                autoComplete="off"
                initialValues={{ items: [{}] }}
                onFinish={onPurchaseRequest}
                className='flex flex-col items-center justify-center'
            >
                <div>
                    <Form.List name="items" >
                        {(fields) => (
                            <ul className='list'>
                                {fields.map((field) => (
                                    <Card
                                        size="default"
                                        key={field.key}
                                        className='card_package'
                                    >
                                        <li className='card_container  h-[160px] gap-y-2'>
                                            <span
                                                className='package_text'
                                            >{listLicensePack && `Gói ${listLicensePack[field.key]?.name}`}</span>
                                            {listLicensePack && listLicensePack[field.key] && listLicensePack[field.key]?.unit_price ?
                                                listLicensePack && listLicensePack[field.key] && listLicensePack[field.key]?.amount ?
                                                    <p>
                                                        <span className='price_text'>{`${((1 - Number(listLicensePack[field.key]?.amount)) * Number(listLicensePack[field.key]?.unit_price)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')} đ`}</span>
                                                        <span className='key_text'> /Key</span>
                                                    </p>
                                                    :
                                                    <p>
                                                        <span className='price_text'>{listLicensePack && `${listLicensePack[field.key]?.unit_price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')} đ`}</span>
                                                        <span className='key_text'> /Key</span>
                                                    </p> : null
                                            }

                                            {listLicensePack && listLicensePack[field.key] && listLicensePack[field.key]?.amount && (
                                                <p className='line-through text-sm text-gray-500'>
                                                    <span >{listLicensePack && `${listLicensePack[field.key]?.unit_price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')} đ`}</span>
                                                    <span > /Key</span>
                                                </p>
                                            )}
                                            <Form.Item label="id" hidden name={[field.name, 'id']}
                                                key={field.key + 'id'}
                                            >
                                                <Input />
                                            </Form.Item>
                                            <span>Số lượng</span>
                                            <Form.Item name={[field.name, 'quantity']}
                                                key={field.key + 'quantity'}
                                                className='!mb-0'
                                            >
                                                <InputNumber placeholder='Tối thiểu 100 keys' min={100} max={100000} step={50} className='w-full' type="number" />
                                            </Form.Item>
                                        </li>
                                    </Card>
                                ))}
                            </ul>
                        )}
                    </Form.List>

                    {keyPrice && keyPrice.original_price ?
                        <Form.Item  
                        className='flex justify-center items-center'
                        >

                            <div
                            className='flex flex-col items-center justify-center w-full gap-y-2 p-4 bg-white rounded-[14px] shadow-card mt-4'>
                                <div>
                                    <div className='text_price_container' >
                                        <div className='text_price_title'>Tổng tiền :</div>
                                        <span className='font-bold'>{parseToVND(keyPrice.final)} VND</span>
                                    </div>
                                </div>
                                <div 
                                className='flex flex-row justify-between w-full'
                                >
                                    <div></div>
                                    <Button onClick={() => form.submit()} className='button_container' type={'primary'}>Đăng ký mua</Button>
                                </div>
                            </div>
                        </Form.Item> : <div></div>}
                </div>
            </Form>
        </div>

    );
};

export default RegisterKey;