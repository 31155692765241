import { Button, DatePicker, Divider, Input, Select, Table } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import { reqGetListKey } from '../../services';
import { ListKey, SummaryItem } from '../../constant/types';
import moment from 'moment';
import './index.css';
import { ActiveKey, InactiveKey, RevokeKey, TotalKey } from '../../assets';
import { useHistory } from 'react-router-dom';
import dayjs from 'dayjs';
import SummaryView from './component/SummaryItem';
const statusFilter = [
    {
        text: 'Chưa kích hoạt',
        value: 1
    },
    {
        text: 'Đã kích hoạt',
        value: 3
    },
    {
        text: 'Tất cả',
        value: 5
    }

]
const Home: React.FC = () => {
    const [listKey, setListKey] = useState<ListKey>();
    const [pageNumber, setPageNumber] = useState<number>(1);
    const [startDateUnix, setStartDateUnix] = useState<number | undefined>(moment().startOf('month').startOf('day').unix());
    const [endDateUnix, setEndDateUnix] = useState<number | undefined>(moment().endOf('day').unix());
    const [statusKey, setStatusKey] = useState<any>();
    const [pageSize, setPageSize] = useState<number>(20);
    const [inputValue, setInputValue] = useState<string>('');
    const [summaryInfor, setSummaryInfor] = useState<SummaryItem[]>([
        {
            "status": 0,
            "count": 0
        },
        {
            "status": 1,
            "count": 0
        },
        {
            "status": 2,
            "count": 0
        }
    ]);
    const [batchIdKey, setBatchIdKey] = useState<string>('');
    const fetchListKey = async (page?: number, startDate?: number, endDate?: number, status?: number, pageSizeK?: number, kms_batch_id?: string) => {
        const statusK = (status == 5) ? undefined : status || statusKey;
        setStatusKey(statusK);
        const finalData: {
            start_date?: number,
            end_date?: number,
            size: number,
            page: number,
            status?: number,
            page_size?: number,
            kms_batch_id?: string,

        } = {
            start_date: startDate == 1 ? undefined : (startDate && !isNaN(startDate)) ? startDate : startDateUnix,
            end_date: endDate == 1 ? undefined : (endDate && !isNaN(endDate)) ? endDate : endDateUnix,
            size: pageSizeK || pageSize,
            page: page || pageNumber,
            status: statusK,
            kms_batch_id: kms_batch_id == '' ? undefined : kms_batch_id || batchIdKey,
        };

        for (const key in finalData) {
            const value = finalData[key as keyof typeof finalData];
            if (value == undefined || value == null || value == '' || value == 0) {
                delete finalData[key as keyof typeof finalData];
            }
        }
        const data: ListKey = await reqGetListKey(finalData);
        setListKey(data);
    };
    useEffect(() => {
        const fetchKeyList = async () => {

            const data: ListKey = await reqGetListKey({
                size: 20, page: 1, start_date: moment().startOf('month').startOf('day').unix(),
                end_date: moment().endOf('day').unix()
            });
            setListKey(data);
            const updatedSummary = [...summaryInfor]; // Create a copy of the existing summaryInfor array

            // Calculate the sum of counts of all status codes
            let totalSum = 0;

            data.summary.forEach((item) => {
                totalSum += Number(item.count);

                const existingItemIndex = updatedSummary.findIndex((existingItem) => existingItem.status === item.status);

                if (existingItemIndex !== -1) {
                    // If an item with the same status already exists, update its count
                    updatedSummary[existingItemIndex].count = item.count;
                } else {
                    // If the item doesn't exist, push it to the updatedSummary array
                    updatedSummary.push(item);
                }
            });

            // Set the count for status code 0 to the total sum
            const statusZeroIndex = updatedSummary.findIndex((existingItem) => existingItem.status === 0);
            if (statusZeroIndex !== -1) {
                updatedSummary[statusZeroIndex].count = totalSum;
            } else {
                updatedSummary.push({ status: 0, count: totalSum });
            }

            setSummaryInfor(updatedSummary)
        };
        fetchKeyList();
    }, []);

    const history = useHistory();
    const navigateRegister = () => {
        history.push('/register');
    };
    const { RangePicker } = DatePicker;
    const dateFormatList = ["DD/MM/YYYY"];

    return (
        <div className=" h-screen h-max-screen flex flex-col items-center">
            <div className='p-8  overflow-hidden'>
                <div
                    className='flex flex-row items-center justify-between w-full mb-1'>
                    <p className="text-4xl font-bold">Trang chủ</p>
                    <div>
                        <Button type='primary' className='font-bold' onClick={navigateRegister}>Đăng ký mua key</Button>
                    </div>
                </div>


                {summaryInfor && <div className='summary_list'>
                    {summaryInfor.map((item, index) => {
                        if (item.status == 1 || item.status == 3 || item.status == 0) {
                        return (
                        <SummaryView
                            key={index}
                            item= {item}
                            imageSrc={item.status === 1 ? InactiveKey : item.status === 3 ? ActiveKey : TotalKey}
                            altText={item.status === 1 ? 'Inactive Key' : item.status === 3 ? 'Active Key' : 'Total Key'}
                            label={item.status === 1 ? 'Tổng số keys chưa kích hoạt' : item.status === 3 ? 'Tổng số keys đã kích hoạt' : 'Tổng số keys được phân phối'}
                        />
                        )
                    }
                })}
                </div>}
                <Divider className='mt-0 mb-1'></Divider>
                <span className='font-bold text-2xl'>Danh sách Key</span>
                <div className='mb-1'>
                    <Input placeholder='Tìm tiếm theo tên lô' className='w-[20%] mr-2'
                        allowClear
                        value={inputValue}
                        onChange={(value) => {
                            const textValue = value.target.value.toString();
                            setBatchIdKey(textValue);
                            setInputValue(textValue);
                            fetchListKey(1, startDateUnix, endDateUnix, statusKey, pageSize, textValue)
                            setPageNumber(1)
                        }
                        }></Input>
                    <span className='font-bold' >Lọc theo ngày tạo: </span>
                    <RangePicker
                        defaultValue={[
                            dayjs().startOf('month'),
                            dayjs()
                        ]}
                        placeholder={["Ngày bắt đầu", "Ngày kết thúc"]}
                        format={dateFormatList}
                        onChange={(value, dateString) => {
                            if (value === null) {
                                setEndDateUnix(undefined);
                                setStartDateUnix(undefined);
                                fetchListKey(1, 1, 1);
                                setPageNumber(1)
                                return;

                            } else {
                                var formattedStartDate = moment(dateString[0], "DD/MM/YYYY").startOf('day');
                                var formattedEndDate = moment(dateString[1], "DD/MM/YYYY").endOf('day');
                                var unixTimestampStart = moment(formattedStartDate).unix();
                                var unixTimestampEnd = moment(formattedEndDate).unix();
                                
                                setStartDateUnix(unixTimestampStart);
                                setEndDateUnix(unixTimestampEnd);
                                setPageNumber(1);
                                fetchListKey(1, unixTimestampStart, unixTimestampEnd);
                            }
                        }}
                    />
                    <span className='font-bold ml-2'>Lọc theo trạng thái: </span>

                    <Select
                        onChange={(value) => {
                            setStatusKey(Number(value));
                            setPageNumber(1);
                            fetchListKey(1, startDateUnix, endDateUnix, Number(value))
                        }}
                        placeholder='Lọc theo trạng thái'
                        value={statusKey}
                        className='w-[200px]'
                        options={
                            statusFilter.map((item) => {
                                return { label: item.text, value: item.value }
                            })
                        } ></Select>
                </div>


                <Table
                    sticky={true}
                    className="overflow-x-hidden overflow-y-auto text-justify h-[100%] pb-52"
                    pagination={
                        {
                            total: listKey?.totalResult,
                            current: pageNumber,
                            onChange: (pageNumberK, pageSizeK) => {
                                if (pageSizeK !== pageSize) {
                                    setPageSize(pageSizeK);
                                    setPageNumber(1);
                                    fetchListKey(1, startDateUnix, endDateUnix, statusKey, pageSizeK)
                                } else {
                                    setPageNumber(pageNumberK);
                                    fetchListKey(pageNumberK, startDateUnix, endDateUnix, statusKey, pageSizeK);
                                }
                            },
                            pageSize: pageSize,
                        }
                    }
                    dataSource={listKey?.page} columns={
                        [
                            {
                                title: "STT",
                                render: (text, record, index) =>
                                    <h4>{index + 1}</h4>,
                                key: 'id',
                                width: 72
                            },
                            {
                                title: "Lô",
                                render: (text, record) =>
                                    <p>{record.kms_batch_id}</p>,
                                key: 'kms_batch_id',
                            },
                            {
                                title: "Key",
                                key: 'key',
                                render: (text, record) =>
                                    <p>{record.key}</p>,
                            },
                            {
                                title: "Ngày tạo",

                                render: (text, record) =>
                                    <p>{moment.unix(record.kms_purchase_date).format('DD/MM/YYYY')}</p>,
                                key: 'created_at',
                            },
                            {
                                title: "Trạng thái",
                                render: (text, record) =>
                                    <div className={record.status == 1 ? 'key inactive_key' : record.status == 3 ? "key active_key" : "key revoke_key"}>
                                        <h4>{record.status == 1 ? 'Chưa kích hoạt' : record.status == 3 ? "Đã kích hoạt" : "Đã thu hồi"}</h4>
                                    </div>,
                                key: 'status',
                                width: 150
                            },
                        ]
                    } />
            </div>
        </div>
    );
};

export default Home;
