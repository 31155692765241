import { useMemo } from 'react';
import { TNotification } from '../../constant/types';
import { Card, List } from 'antd';
import { getTimeFromUnixTimestamp, unixToDateString } from '../../helper';
import { reqReadNotification } from '../../services';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../../redux/reducers';
import { MANAGE_COMMON } from '../../redux/actions/actionTypes';
import { ReadNotification, UnreadNotification } from '../../assets';
import clsx from 'clsx';
import { useHistory } from 'react-router-dom';

const Notification = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const { listNotification } = useSelector((app: AppState) => app.common);

    const readNotification = async (notification_id: number, redirect_path: string) => {
        const response = await reqReadNotification({
            notification_id: notification_id
        })
        if (response) {
            dispatch(MANAGE_COMMON.COUNT_NOTIFICATION());
        }
        history.push(redirect_path);
    }

    const listNotificationView = useMemo(() => {
        return (
            <List
                className='w-full'
          
                dataSource={listNotification}
                renderItem={(item: TNotification) => (
                    <List.Item
                        className='w-full cursor-pointer'
                        onClick={() => readNotification(item.id, item.redirect_path)}
                    >
                        <Card className='shadow-card w-[50%] max-w-[580px]' >
                            <div
                                className='flex flex-row items-center w-full justify-around'
                            >
                                <img src={item.status === 0 ? UnreadNotification : ReadNotification} alt="notification" className='w-8 h-8 mr-4'></img>
                                <div
                                    className={clsx('flex flex-col opacity-70 ', 
                                     {'!opacity-100':  item.status === 0,}
                                    )
                                }
                                >
                                    <span className='text-[16px] font-semibold'>

                                        {item.message}
                                    </span>
                                    <div
                                        className={clsx('flex flex-row items-center gap-2', {
                                            'font-semibold': item.status === 0,
                                            'font-normal': item.status !== 0,
                                            'text-grey': item.status !== 0,
                                            "text-base": item.status === 0,
                                        
                                        })}
                                    >
                                        <p>{unixToDateString(item.created_at)}</p>
                                        <p>{getTimeFromUnixTimestamp(item.created_at)} - </p>
                                        <p>{item.status === 0 ? "Chưa đọc" : "Đã đọc"}</p>
                                    </div>
                                </div>
                                <div
                                    className={clsx(' rounded-full !w-[12px] !h-[12px] ml-2 bg-base invisible ', {
                                        '!visible': item.status === 0,
                                    })}
                                />
                            </div>
                        </Card>

                    </List.Item>
                )}
            >
            </List>
        );
    }, [listNotification]);
    return (
        <div className="h-screen w-full flex flex-col items-center overflow-y-auto  p-8">
            <div className='flex flex-row  w-full items-start'>
                <p className="text-4xl font-bold">Thông báo</p>
            </div>
            {listNotificationView}
        </div>

    );
};

export default Notification;