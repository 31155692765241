import { TNotification } from '../../constant/types';
import { ActionType } from '../actions/actionTypes';
import { COMMON_STATE } from '../actions/types';


export interface IManageCommon {
    notificationsCount: number | undefined,
    listNotification: TNotification[] | [],
    purchaseId: string,
    loading: boolean
}

const initialState: IManageCommon = {
    notificationsCount: 0,
    listNotification: [],
    purchaseId: '',
    loading: false



};
const rootCommon = (state = initialState, action: ActionType) => {
    switch (action.type) {
        case COMMON_STATE:
            return { ...state, ...action.payload };

        default:
            return state;
    }
};
export default rootCommon;
