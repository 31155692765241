import React, { useEffect, useState } from 'react';
import './index.css';
import { reqCancelPurchaseRequest, reqGetListPurchaseRequest } from '../../services';
import { PurchaseRequest, ResListPurchaseRequestData } from '../../constant/types';
import { Button, DatePicker, Input, Select, Table, message } from 'antd';
import { parseToVND } from '../../helper';
import { dateFormatList } from '../../constant';
import dayjs from 'dayjs';
import moment from 'moment';
const statusFilter = [
    {
        text: 'Đã xác minh nhưng chưa được phân phối',
        value: 1,
    },
    {
        text: 'Đã được phân phối',
        value: 2,
    },
    {
        text: 'Đã bị từ chối',
        value: 3,
    },
]

const ListRequest: React.FC = () => {
    const [listRequest, setListRequest] = useState<ResListPurchaseRequestData>();
    const [pageNumber, setPageNumber] = useState<number>(1);
    const [startDateUnix, setStartDateUnix] = useState<number | undefined>(moment().startOf('month').startOf('day').unix());
    const [endDateUnix, setEndDateUnix] = useState<number | undefined>(moment().endOf('day').unix());
    const [statusKey, setStatusKey] = useState<number>();
    const [pageSize, setPageSize] = useState<number>(20);
    const [inputValue, setInputValue] = useState<string>('');
    const [batchIdKey, setBatchIdKey] = useState<string>('');

    const getListRequest = async (page?: number, startDate?: number, endDate?: number, status?: number, pageSizeK?: number, batch_id?: string) => {
        const statusK = (status === 5) ? undefined : status;

        const finalData: {
            start_date?: number,
            end_date?: number,
            size: number,
            page: number,
            status?: number,
            page_size?: number,
            batch_id?: string,
        } = {
            start_date: startDate == 1 ? undefined : (startDate && !isNaN(startDate)) ? startDate : startDateUnix,
            end_date: endDate == 1 ? undefined : (endDate && !isNaN(endDate)) ? endDate : endDateUnix,
            size: pageSizeK || pageSize,
            page: page || pageNumber,
            status: statusK,
            batch_id: batch_id == '' ? undefined : batch_id || batchIdKey,
        };

        for (const key in finalData) {
            const value = finalData[key as keyof typeof finalData];
            if (value == undefined || value == null || value == '' || value == 0) {
                delete finalData[key as keyof typeof finalData];
            }
        }
        const response = await reqGetListPurchaseRequest(finalData);
        setListRequest(response);

    };

    useEffect(() => {
        getListRequest(
            1,
            moment().startOf('month').startOf('day').unix(),
            moment().endOf('day').unix(),
            undefined,
            20
        )
    }, []);

    const rejectPurchase = async (id: string) => {
        const response = await reqCancelPurchaseRequest({ batch_id: id })
        if (response) {
            getListRequest()
            message.success('Huỷ đặt hàng thành công')
        }
        else {
            message.error('Có lỗi xảy ra. Vui lòng thử lại.')
        }
    }

    const columns = [
        {
            title: 'Tên lô',
            dataIndex: 'batch_id',
            key: 'batch_id',
        },
        {
            title: 'Ngày mua',
            key: 'created_at',
            render: (text: any, record: PurchaseRequest) => {

                return <p>{moment.unix(record.created_at).format("DD/MM/YYYY")}</p>;
            }
        },
        {
            title: "Trạng thái",
            render: (text: any, record: PurchaseRequest) =>
                <div className={record.status == 0 ? 'key inactive_key' : record.status == 1 ? "key inactive_key" : record.status == 2 ? "key active_key" : "key revoke_key"}>
                    <h4 >{record.status == 0 ? 'Chưa xác minh' : record.status == 1 ? "Đã xác minh" : record.status == 2 ? "Đã được phân phối" : "Đã bị từ chối"}</h4>
                </div>,
            key: 'status',
        },
        {
            title: 'Chi tiết',
            key: 'detail',
            render: (text: any, record: PurchaseRequest) => {

                return record.detail.map((item: any) => {
                    return <p>Gói {item.license_pack} - Số lượng {item.quantity} {item.quantity > 1 ? "keys" : "key"} </p>;
                })
            }
        },
        {
            title: 'Tổng tiền ',
            dataIndex: 'total_price',
            key: 'total_price',
            render: (text: any, record: PurchaseRequest) => {
                return <p>{parseToVND(Number(record.total_price))} VND</p>;
            }
        },
        {
            title: "Tình trạng thanh toán",
            render: (text: any, record: PurchaseRequest) =>
                <div className={record.payment_status == 0 ? 'key revoke_key' : record.payment_status == 1 ? "key active_key" : "null"}>
                    <h4 >{record.payment_status == 0 ? 'Chưa thanh toán' : record.payment_status == 1 ? "Đã thanh toán" : null }</h4>
                </div>,
            key: 'status',
        },
        {
            title: 'Action',
            key: 'detail',
            render: (text: any, record: PurchaseRequest) => {
                return record.status == 1 && <div>
                    <Button onClick={() => rejectPurchase(record.batch_id)}>Huỷ đăng ký mua</Button>
                </div>
            }
        }
    ]

    const { RangePicker } = DatePicker;
    return (
        <div className=" h-screen flex flex-col items-center p-8 bg-white">
            <div className='container_sub_content'>
                <p className="text-4xl font-bold">Lịch sử đặt hàng</p>
                <div className='flex flex-row items-center gap-2 mt-[12px] mb-[12px]'>
                    <Input placeholder='Tìm tiếm theo tên lô' className='w-[20%]'
                        allowClear
                        value={inputValue}
                        onChange={(value) => {
                            const textValue = value.target.value.toString();
                            setBatchIdKey(textValue);
                            setInputValue(textValue);
                            getListRequest(1, startDateUnix, endDateUnix, statusKey, pageSize, textValue);
                            setPageNumber(1);
                        }
                        }></Input>
                    <span className='font-bold'>Lọc theo ngày mua: </span>
                    <RangePicker
                        className="mr-3"
                        defaultValue={[
                            dayjs().startOf('month'),
                            dayjs()
                        ]}
                        placeholder={["Ngày bắt đầu", "Ngày kết thúc"]}
                        format={dateFormatList}
                        onChange={(value, dateString) => {
                            setInputValue('');
                            if (value === null) {
                                setEndDateUnix(undefined);
                                setStartDateUnix(undefined);
                                getListRequest(1, 1, 1);
                                setPageNumber(1)
                                return;

                            } else {
                                var formattedStartDate = moment(dateString[0], "DD/MM/YYYY").startOf('day');
                                var formattedEndDate = moment(dateString[1], "DD/MM/YYYY").endOf('day');
                                var unixTimestampStart = moment(formattedStartDate).unix();
                                var unixTimestampEnd = moment(formattedEndDate).unix();
                                setStartDateUnix(unixTimestampStart);
                                setEndDateUnix(unixTimestampEnd);
                                setPageNumber(1);
                                getListRequest(1, unixTimestampStart, unixTimestampEnd);
                            }
                        }}
                    />
                    <Select
                        onChange={(value) => {
                            setStatusKey(Number(value));
                            setPageNumber(1);
                            getListRequest(1, startDateUnix, endDateUnix, Number(value));
                        }}
                        placeholder='Lọc theo trạng thái'

                        value={statusKey}
                        className='w-[200px]'
                        options={
                            statusFilter.map((item) => {
                                return { label: item.text, value: item.value }
                            }).concat([{ label: 'Tất cả', value: 5 }])
                        } ></Select>
                </div>
                <Table
                    className="overflow-x-hidden overflow-y-auto text-justify h-[100%] pb-24"
                    dataSource={listRequest?.page}
                    columns={columns}
                    pagination={
                        {
                            total: listRequest?.totalResult,
                            current: pageNumber,
                            onChange: (pageNumberK, pageSizeK) => {
                                if (pageSizeK !== pageSize) {
                                    setPageSize(pageSizeK);
                                    setPageNumber(1);
                                    getListRequest(1, startDateUnix, endDateUnix, statusKey, pageSizeK)
                                } else {
                                    setPageNumber(pageNumberK);
                                    getListRequest(pageNumberK, startDateUnix, endDateUnix, statusKey, pageSizeK);
                                }
                            },
                            pageSize: pageSize,
                        }
                    }
                />
            </div>
        </div>
    );
};

export default ListRequest;