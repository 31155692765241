

export const GET_NOTIFICATION_COUNT = 'GET_NOTIFICATION_COUNT';
export const GET_NOTIFICATION_COUNT_SUCCESS = 'GET_NOTIFICATION_COUNT_SUCCESS';

export const GET_PURCHASE_REQUEST_SUCCESS = "GET_PURCHASE_REQUEST_SUCCESS"
export const GET_PURCHASE_REQUEST = "GET_PURCHASE_REQUEST"
export const REMOVE_PURCHASE_REQUEST = "REMOVE_PURCHASE_REQUEST"

export const UPDATE_LOADING = "UPDATE_LOADING"

export const COMMON_STATE = "COMMON_STATE"