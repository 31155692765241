import { Button, Form, Input } from 'antd';
import React from 'react';
import { reqChangePassword } from '../../services';
import { regrexAtLeast } from '../../constant';


const ChangePassword: React.FC = () => {
    return (
        <div className='page_container'>
            <div className='content'>
                <span className='login_heading'>Đổi mật khẩu</span>
                <Form onFinish={reqChangePassword} className='form_login'>

                    <Form.Item
                        rules={[{
                            required: true,
                            validateTrigger: 'onFinish',
                            message: 'Vui lòng nhập mật khẩu cũ'
                        },
                        { min: 8, validateTrigger: 'onFinish', message: 'Mật khẩu phải có ít nhất 8 ký tự' },

                        ]}
                        name='current_password'
                    >
                        <Input.Password className='input_form' placeholder='Mật khẩu cũ' />
                    </Form.Item>
                    <Form.Item
                        rules={[{
                            required: true,
                            validateTrigger: 'onFinish',
                            message: 'Vui lòng nhập mật khẩu'
                        },
                        { min: 8, validateTrigger: 'onFinish', message: 'Mật khẩu phải có ít nhất 8 ký tự' },
                        {
                            validateTrigger: 'onFinish',
                            validator: (_, value) =>
                                regrexAtLeast.test(value) ? Promise.resolve() :
                                    Promise.reject('Mật khẩu có chứa ít nhất 1 kí tự số và 1 kí tự chữ cái'
                                    )
                        }

                        ]}
                        name='new_password'
                    >
                        <Input.Password className='input_form' placeholder='Mật khẩu mới' />
                    </Form.Item>
                    <Form.Item
                        rules={[{
                            required: true,
                            validateTrigger: 'onFinish',
                            message: 'Vui lòng nhập mật khẩu'
                        },
                        { min: 8, validateTrigger: 'onFinish', message: 'Mật khẩu phải có ít nhất 8 ký tự' },

                        {
                            validateTrigger: 'onFinish',
                            validator: (_, value) =>
                                regrexAtLeast.test(value) ? Promise.resolve() :
                                    Promise.reject('Mật khẩu có chứa ít nhất 1 kí tự số và 1 kí tự chữ cái'
                                    )
                        },
                        ({ getFieldValue }) => ({
                            validateTrigger: 'onFinish',
                            validator(_, value) {
                                if (!value || getFieldValue('new_password') === value) {
                                    return Promise.resolve();
                                }
                                return Promise.reject(new Error('Mật khẩu không trùng khớp'));
                            },
                        }),
                        ]}
                        name='confirm_new_password'
                    >
                        <Input.Password className='input_form' placeholder='Nhập lại mật khẩu mới' />
                    </Form.Item>
                    <Button className='form_button' type='primary' htmlType='submit'>Đổi mật khẩu</Button>
                </Form>

            </div>

        </div>
    );
};

export default ChangePassword;