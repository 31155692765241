import React from 'react';

type SummaryItemProps = {
  item: {
    count: number;
    status: number;
  };
  imageSrc: string;
  altText: string;
  label: string;
};

const SummaryView: React.FC<SummaryItemProps> = ({
  item,
  imageSrc,
  altText,
  label,
}) => (
  <div className='summary_container'>
    <div className='summary_key summary_inactive_key'>
      <img className='img_summary' src={imageSrc} alt={altText} />
      <div className='summary_text_container'>
        <span className='sumary_ammount'>{item.count}</span>
        <span>{label}</span>
      </div>
    </div>
  </div>
);

export default SummaryView;