import { useEffect } from 'react';
import './App.css';
import { Switch, Redirect, Route, useLocation, useHistory } from "react-router-dom";
import Home from './pages/Home';
import Login from './pages/Login';
import SideBar from './component/Sidebar';
import { Layout } from 'antd';
import RegisterKey from './pages/RegisterKey';
import ChangePassword from './pages/ChangePassword';
import ListRequest from './pages/ListRequest';
import Notification from './pages/Notification';
import { Provider } from 'react-redux';
import { store } from './redux/store';
import { LOGIN_PATH } from './constant';

const { Content } = Layout;


function App() {
  const location = useLocation();
  const history = useHistory();

  const checkIsLogin = () => {
    const token = localStorage.getItem('auth')
    return token !== null && token !== undefined && token !== ''
  };

  useEffect(() => {
    const isLogin = checkIsLogin();
    if (location.pathname === '/') {
      if (!isLogin) {
        history.push(LOGIN_PATH);
      }
    } else if (location.pathname === LOGIN_PATH) {

      if (isLogin) {
        history.push('/');
      }
    }
  }, [history, location.pathname]);

  return (
    // <PersistGate loading={null} persistor={persistor}>
    <Provider store={store}>
      <Switch>
        <Route exact path='/login' component={Login} />
        {checkIsLogin() &&
            <div className='page_container'>
              <SideBar />
              <Layout>
                <Content>
                  <Switch>
                    <Route exact path="/">
                      <Home />
                    </Route>
                    <Route exact path="/register">
                      <RegisterKey />
                    </Route>
                    <Route exact path="/change-password">
                      <ChangePassword />
                    </Route>
                    <Route exact path="/notification">
                      <Notification />
                    </Route>
                    <Route exact path="/history">
                      <ListRequest />
                    </Route>
                    <Route
                      render={props =>
                        <Redirect
                          to={{
                            pathname: checkIsLogin() ? '/' : LOGIN_PATH,
                            state: { from: location }
                          }}
                        />}
                    />
                  </Switch>
                </Content>
              </Layout>
            </div>
        }
        <Route
          render={props =>
            <Redirect
              to={{
                pathname: checkIsLogin() ? '/' : LOGIN_PATH,
                state: { from: location }
              }}
            />}
        />
      </Switch>
    </Provider>
  );
}

export default App;
