import React from 'react';
import './index.css';
import { Button, Form, Input } from 'antd';
import { Logo } from '../../assets';
import { reqAuthLogin } from '../../services';

const Login: React.FC = () => {
    
    return (
        <div className='page_container'>
            <div className='content'>
            <img alt='logo' src={Logo}/>
            <span className='login_heading' >Đăng nhập</span>
            <span>Đăng nhập bằng tài khoản đối tác</span>
            <Form onFinish={reqAuthLogin} className='form_login'>
                <Form.Item 
                rules={[
                    { required: true, validateTrigger: 'onFinish', message: 'Vui lòng nhập email'},
                    {
                      type: 'email',
                      validateTrigger: 'onFinish',
                      message: 'Email không hợp lệ'
                    }
                  ]}
                  name='email'
                >
                    <Input className='input_form' placeholder='Email' />
                </Form.Item>
                <Form.Item 
               rules= {[{
                    required: true,
                    validateTrigger: 'onFinish',
                    message: 'Vui lòng nhập mật khẩu'
                  },
                    { min: 8, validateTrigger: 'onFinish', message: 'Mật khẩu phải có ít nhất 8 ký tự'}
                   
                  ]}
                  name='password'
                >
                    <Input.Password type='password' className='input_form' placeholder='Mật khẩu' />
                </Form.Item>
                <Button className='form_button' type='primary' htmlType='submit'>Đăng nhập</Button>
            </Form>
            </div>
          
        </div>
    );
};

export default Login;
