import { useEffect, useState } from 'react';
import { Image, Menu } from 'antd';
import { Link, useHistory } from 'react-router-dom';
import { Home, LogoWhite, ChangePassword, History, Logout, Notification } from '../../assets';
import './index.css';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../../redux/reducers';
import { MANAGE_COMMON } from '../../redux/actions/actionTypes';
import { LOGIN_PATH } from '../../constant';
const listPathSideBar = [
    '/',
    '/notification',
    '/history',
    '/change-password',
]
const SideBar = () => {
    const [activeKey, setActiveKey] = useState<string>();
    const pathname = window.location.pathname //returns the current url minus the domain name
    useEffect(() => {
            setActiveKey((listPathSideBar.indexOf(pathname)).toString())
    }, []);
   
    const history = useHistory();

    const dispatch = useDispatch();
    const { notificationsCount } = useSelector((app: AppState) => app.common);
  
    useEffect(() => {
      dispatch(MANAGE_COMMON.COUNT_NOTIFICATION());
    }, []);
    
    return (
        <div className='background'>
            <Menu
                mode='inline'
                onClick={(e) => setActiveKey(e.key.toString())}
                theme="dark"
                activeKey={activeKey}
                className="menu"
                defaultOpenKeys={['0']}
                
                >
                <Image
                    preview={false} src={LogoWhite}
                    className='logo_image'

                />
                <Menu.Item key="0" className="menu_item" icon={
                    <img className="w-[24px] h-[24px]"
                        src={Home}></img>
                } >
                    <Link to={'/'} className='text-sm'>Trang chủ</Link>
                </Menu.Item>
                <Menu.Item key="1" className="menu_item" 
                    icon={
                        <img className="w-[24px] h-[24px]"
                            src={Notification}></img>
                    }
                >
                    <Link to={'/notification'}>
                        {notificationsCount > 0 ? <div className='text-sm'>Thông báo ({notificationsCount})</div> : <div className='text-sm'>Thông báo</div>}
                    </Link>
                </Menu.Item>
                <Menu.Item key="3" className="menu_item" icon={
                    <img className="w-[24px] h-[24px]"
                        src={History}></img>
                } >
                    <Link to={'/history'} className='text-sm'>Lịch sử đặt hàng</Link>
                </Menu.Item>
                <Menu.Item key="4" className="menu_item" icon={
                    <img className="w-[24px] h-[24px]"
                        src={ChangePassword}></img>
                } >
                    <Link to={'/change-password'} className='text-sm'>Đổi mật khẩu</Link>
                </Menu.Item>

                <Menu.Item key="5" className="menu_item !absolute bottom-0" icon={
                    <img className="w-[24px] h-[24px]"
                        src={Logout}></img>
                }
                    onClick={() => {
                        localStorage.removeItem('auth');
                        localStorage.removeItem('refreshToken');
                        history.push(LOGIN_PATH);
                    }}
                >
                   <p className='text-sm'>Đăng xuất</p> 
                </Menu.Item>

            </Menu>
        </div>
    );
};

export default SideBar;
