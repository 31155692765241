import { message } from "antd";
import { AuthLoginResponse, CalculatePriceData, LoginData, PayLoadGetListKey, PayloadChangePassword, PayloadListInvoice, PayloadListPurchaseRequest, PayloadVerifyPurchase, RenewTokenData, ResCalculatePrice, ResGetInvoice, ResGetNotification, ResLicensePack, ResListKey, ResListPurchaseRequest, ResPurchaseId, ResPromotionValid } from "../constant/types";
import { ApiResponse, axiosAuth, axiosCatch } from "./AxiosHelper";
import { AxiosResponse } from "axios";
import { LOGIN_PATH } from "../constant";

export const reqAuthLogin = async (loginData: LoginData,) => {
  const response: ApiResponse<AuthLoginResponse> = await axiosAuth().post('/auth/login', loginData).catch(axiosCatch);
  const { accessToken, refreshToken } = response.data.data;
  if (accessToken) {
    message.success('Đăng nhập thành công');
    localStorage.setItem('auth', accessToken);
    localStorage.setItem('refreshToken', refreshToken);
    window.location.replace(LOGIN_PATH);
  }
  else {
    message.error('Đăng nhập thất bại');
  }

};

export const reqAuthRenewToken = async (renewTokenData: RenewTokenData) => {
  return await axiosAuth().post('/auth/refresh-tokens', renewTokenData).catch(axiosCatch);
};


export const reqGetListKey = async (params: PayLoadGetListKey) => {
  const response: ApiResponse<ResListKey> = await axiosAuth().get('/license-key/get-list-key', { params }).catch(axiosCatch);
  return response.data.data;
};


export const reqLicensePack = async () => {
  const response: ApiResponse<ResLicensePack> = await axiosAuth().get('/license-pack/list-license-pack').catch(axiosCatch);
  return response.data.data;
}

export const reqGetPromotion = async () => {
  const response: ApiResponse<ResPromotionValid> = await axiosAuth().get('/promotion/get-promotion').catch(axiosCatch);
  return response.data.data;
}

export const reqCalculatePrice = async (data: CalculatePriceData) => {
  const calculate_price: AxiosResponse<ResCalculatePrice> = await axiosAuth().post('/purchase/calculate-price', data).catch(axiosCatch);
  return calculate_price.data.data;
};

export const reqPurchaseRequest = async (data: CalculatePriceData) => {
  const calculate_price: AxiosResponse<ResPurchaseId> = await axiosAuth().post('/purchase/purchase-request', data).catch(axiosCatch);
  return calculate_price.data.data.batch_id;
};

export const reqChangePassword = async (data: PayloadChangePassword) => {
  const passwordChange = await axiosAuth().post('/auth/change-password', data).catch(axiosCatch);
  if (passwordChange.data) {
    message.success('Đổi mật khẩu thành công');
  }
};

export const reqVerifyPurchase = async (data: PayloadVerifyPurchase) => {
  const purchaseVerified = await axiosAuth().post('/purchase/verify-purchase-request', data).catch(axiosCatch);

  if (purchaseVerified.data && purchaseVerified.data.code == 200) {
    return true
  }
  else {
    return false
  }
};

export const reqGetListPurchaseRequest = async (params : PayloadListPurchaseRequest) => {
  const response: ApiResponse<ResListPurchaseRequest> = await axiosAuth().get('/purchase/get-list-purchase-request', {params}).catch(axiosCatch);
  return response.data.data;
}

export const reqCancelPurchaseRequest = async (data: { batch_id: string }) => {
  const response = await axiosAuth().post('/purchase/cancel-purchase-request', data).catch(axiosCatch);

  if (response.data.code && response.data.code == 200) {
    return true
  }
  else {
    return false
  }
}

export const reqGetNotification = async () => {
  const response: ApiResponse<ResGetNotification> = await axiosAuth().get('/notification/get-notification').catch(axiosCatch);
  return response.data.data;
}

export const reqReadNotification = async (data: { notification_id: number }) => {
  const response = await axiosAuth().post('/notification/confirm-read-notification', data).catch(axiosCatch);

  if (response.data.code && response.data.code == 200) {
    return true
  }
  else {
    return false
  }
}

export const reqGetListInvoice = async (params: PayloadListInvoice) => {
  const response: ApiResponse<ResGetInvoice> = await axiosAuth().get('/invoice/get-list-invoice', {params}).catch(axiosCatch);

 return response.data.data;
}

export const reqAcceptPurchaseRequest = async (data: { batch_id: string }) => {
  const response = await axiosAuth().post('/purchase/accept-purchase-request', data).catch(axiosCatch);
  if (response.data.code && response.data.code == 200) {
    return true
  }
  else {
    return false
  }
}