import { all, call, put, takeLatest } from 'redux-saga/effects';
import { ActionType, MANAGE_COMMON } from '../../actions/actionTypes';
import { GET_NOTIFICATION_COUNT, GET_PURCHASE_REQUEST, REMOVE_PURCHASE_REQUEST } from '../../actions/types';
import { reqGetNotification, reqPurchaseRequest } from '../../../services';
import { PurchaseId, TNotification } from '../../../constant/types';

function* getNotification() {
  // yield put(MANAGE_COMMON.COUNT_NOTIFICATION(payload));
  try {
    const response: TNotification[] = yield call(reqGetNotification);
    const count = response.filter((item) => item.status == 0).length

    yield put(MANAGE_COMMON.COUNT_NOTIFICATION_SUCCESS({
      notificationsCount: count,
      listNotification: response
    }));
  } catch (e) {
    // message.error(e.message);
  } finally {
    // yield put(COMMON.LOADING(false));
  }
}

function* getPurchaseRequest({ payload }: ActionType) {
  yield put(MANAGE_COMMON.LOADING_UPDATE({ loading: true }));

  try {
    const response: PurchaseId = yield call(reqPurchaseRequest, payload);
    yield put(MANAGE_COMMON.PURCHASE_REQUEST_SUCCESS({
      purchaseId: response,
    }));
  } catch (e) {
    // message.error(e.message);
  } finally {
    // yield put(COMMON.LOADING(false));
    yield put(MANAGE_COMMON.LOADING_UPDATE({ loading: false }));

  }
}

function* removePurchaseRequest({ payload }: ActionType) {
  // yield put(MANAGE_COMMON.COUNT_NOTIFICATION(payload));

  yield put(MANAGE_COMMON.PURCHASE_REQUEST_SUCCESS({
    purchaseId: '',
  }));
}



export default function* commonSagas() {
  yield all([
    takeLatest(GET_NOTIFICATION_COUNT, getNotification),
    takeLatest(GET_PURCHASE_REQUEST, getPurchaseRequest),
    takeLatest(REMOVE_PURCHASE_REQUEST, removePurchaseRequest),

  ]);
}
