import { COMMON_STATE, GET_NOTIFICATION_COUNT, GET_PURCHASE_REQUEST, REMOVE_PURCHASE_REQUEST } from "./types";

export interface ActionType {
  type: string,
  payload: any
}

export const MANAGE_COMMON = {
  COUNT_NOTIFICATION: () => {
    return {
      type: GET_NOTIFICATION_COUNT,
      
    };
  },
  COUNT_NOTIFICATION_SUCCESS: (payload= {}) => {
    return {
      type: COMMON_STATE,
      payload
    };
  },
  PURCHASE_REQUEST: (payload = {}) => {
    return {
      type: GET_PURCHASE_REQUEST,
      payload
      
    };
  },
  PURCHASE_REQUEST_SUCCESS: (payload= {}) => {
    return {
      type: COMMON_STATE,
      payload
    };
  },
  PURCHASE_REQUEST_REMOVE: (payload= {}) => {
    return {
      type: REMOVE_PURCHASE_REQUEST,
      payload
    };
  },

  LOADING_UPDATE : (payload = {}) => {
    return {
      type: COMMON_STATE,
      payload
    }
  }
}